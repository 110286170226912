import PropTypes from 'prop-types';
import { Auth } from '@aws-amplify/auth';
import { useMemo, useEffect, useReducer, useCallback } from 'react';

import { AMPLIFY_API } from '../../../config-global';

import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

const userPayload = user => ({
  ...user,
  id: user.attributes.sub,
  displayName: user.attributes.name,
  role: 'admin',
});

const DEVICE_KEY = 'CognitoIdentityServiceProvider.OceanSquash'

// ----------------------------------------------------------------------

Auth.configure(AMPLIFY_API);

export async function getUserIdToken() {
  try {
      const currentSession = await Auth.currentSession();
      if(currentSession) {
        return currentSession.idToken.jwtToken;
      }
  } catch(error) { ; }
  throw new Error('Not Logged in');
}

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const currentUser = await Auth.currentAuthenticatedUser();

      if (currentUser) {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: userPayload(currentUser),
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (userid, password) => {
    const deviceKey = localStorage.getItem(DEVICE_KEY);
    let currentUser = await Auth.signIn(userid, password, { deviceKey });

    // Forced to complete new password and re-signin
    if(currentUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      await Auth.completeNewPassword(currentUser, password);
      currentUser = await Auth.signIn(userid, password, { deviceKey });
    }
    if(currentUser.signInUserSession) {
      dispatch({
        type: 'INITIAL',
        payload: {
          user: userPayload(currentUser),
        },
      });
      return null;
    }
    return currentUser;
  }, []);

  const login2 = useCallback(async (user, authCode) => {
    let deviceKey = localStorage.getItem(DEVICE_KEY);
    if(!deviceKey) { deviceKey = crypto.randomUUID(); }

    const currentUser = await Auth.sendCustomChallengeAnswer(
      user, authCode, { deviceKey }
    );
    if(currentUser.signInUserSession) {
      dispatch({
        type: 'INITIAL',
        payload: {
          user: userPayload(currentUser),
        },
      });
      localStorage.setItem(DEVICE_KEY, deviceKey);

    } else {
      throw new Error('ERR_CODE.');
    }
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        given_name: firstName,
        family_name: lastName,
      },
    });
  }, []);

  // CONFIRM REGISTER
  const confirmRegister = useCallback(async (email, code) => {
    await Auth.confirmSignUp(email, code);
  }, []);

  // RESEND CODE REGISTER
  const resendCodeRegister = useCallback(async (email) => {
    await Auth.resendSignUp(email);
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await Auth.signOut();
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email) => {
    await Auth.forgotPassword(email);
  }, []);

  // NEW PASSWORD
  const newPassword = useCallback(async (email, code, password) => {
    await Auth.forgotPasswordSubmit(email, code, password);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'amplify',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login, login2,
      logout,
      register,
      newPassword,
      forgotPassword,
      confirmRegister,
      resendCodeRegister,
    }),
    [
      status,
      state.user,
      //
      login, login2,
      logout,
      register,
      newPassword,
      forgotPassword,
      confirmRegister,
      resendCodeRegister,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
