import { filter } from 'lodash';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Tab, Tabs, Card, Table, Stack, Paper, Button,
  TableContainer, TableBody, TableRow, TableCell,
  Container, Typography, IconButton,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
// utils
import { calcAge, calcTheAge, ascComparer, descComparer } from '../utils/utilitys';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { BloodListHead } from '../sections/@dashboard/blood';
// context
import { UserContext, TaskContext } from '../context';
// global
import { STAT } from '../config-global';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'status' },
  { id: 'visitDate', label: '実施日',   alignRight: false },
  { id: 'patient',   label: '患者情報', alignRight: false },
  { id: 'memo',      label: '依頼内容', alignRight: false },
  { id: '' },
];

const STATUS_ICON = {
  [STAT.New]: 'eva:clock-outline',             // 募集中
                                               // 確定
  [STAT.Fin]: 'eva:checkmark-circle-2-fill',   // 完了
  [STAT.Can]: 'eva:close-fill',                // キャンセル
};

const STATUS_OPTIONS = [
  { value: STAT.All, label: 'すべて' },
  { value: STAT.New, label: '募集中' },
  { value: STAT.Fix, label: '確定' },
  { value: STAT.Fin, label: '完了' },
  { value: STAT.Can, label: 'キャンセル' },
  { value: STAT.Chk, label: '確認済', icon: 'eva:checkmark-fill' },
];
// ----------------------------------------------------------------------

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descComparer(a, b, orderBy)
    : (a, b) => ascComparer(a, b, orderBy);
}

function applySortFilter(array, comparator, status, facID) {
  array = filter(array, (_blood) => {
    const checked = _blood.ChkIDs[facID];
    return (status === STAT.All) ? !checked :
            ((status === STAT.Chk) ? checked :
              (_blood.Stat === status) && !checked);
  });
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function BloodPage() {
  const mdUp = useResponsive('up', 'sm');
  const navigate = useNavigate();
  const { userObj } = useContext(UserContext);
  const { taskList, taskRefresh, taskCheck } = useContext(TaskContext);
  const [filterStatus, setFilterStatus] = useState(STAT.All);

  const nowDate = new Date();

  const handleNewBlood = () => {
    navigate('/dashboard/blood/patient-sel');
  };

  const handleEditBlood = (e) => {
    navigate('/dashboard/blood/edit', { state: {
      id: e.currentTarget.dataset.id
    }});
  }

  useEffect(() => {
    taskCheck(true);
    return () => taskCheck(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filteredBloods = applySortFilter(
    taskList.tasks, getComparator('asc', 'Sched'), filterStatus, userObj.FacID);

  const isNotFound = !filteredBloods.length;
  const notFoundElement =
    <Paper sx={{ textAlign: 'center', }}>
      <Typography variant="h6" paragraph>
        ...
      </Typography>
    </Paper>;

  const largeFont = { fontSize: 'larger', fontWeight: 'bold' };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="end" mb={5}>
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Button variant="contained" startIcon={<Iconify icon="eva:refresh-fill" />} onClick={taskRefresh}>
            更新
          </Button>
          {(userObj.FacRole?.Register ?? false) &&
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleNewBlood}>
            新規採血
          </Button>
          }
        </Stack>
      </Stack>

      <Card>
        <Tabs
          value={filterStatus}
          onChange={(event, status) => setFilterStatus(status)}
          sx={{
            px: mdUp ? 2.5 : 0.0,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label}
              icon={<Iconify icon={tab.icon ?? STATUS_ICON[tab.value]} />}
            />
          ))}
        </Tabs>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <BloodListHead
                headLabel={TABLE_HEAD}
              />
              <TableBody>
                {filteredBloods.map((row) => {
                  const { TaskID, TaskNo, PatNm, PatBrth, PatAdr1, PatAdr2, ReqMem, Sched, Stat } = row;
                  const imContractor = (userObj.FacID === row.FacID) || (userObj.FacID === row.DcdID);

                  return (
                    <TableRow hover key={TaskID} tabIndex={-1} style={{ cursor: 'pointer' }}
                      data-id={TaskID} onClick={handleEditBlood}>
                      <TableCell align="center">
                        <Iconify width="30px" icon={STATUS_ICON[Stat] ?? null} />
                      </TableCell>
                      <TableCell align="left">
                        <span style={largeFont}>{Sched}</span><br />
                        [{TaskNo}]
                      </TableCell>
                      <TableCell align="left">
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                          <span style={largeFont}>{PatNm}</span>
                          <span>
                            {imContractor ?
                              `${PatBrth}生（${calcAge(new Date(PatBrth), nowDate)}歳）` :
                              `＊＊＊＊＊生（${calcTheAge(new Date(PatBrth), nowDate)}代）`
                            }
                          </span>
                        </Stack>
                        {PatAdr1}{imContractor ? PatAdr2 : null}
                      </TableCell>
                      <TableCell align="left">
                        {(() => {
                          const list = ReqMem.split('\n');
                          return list.slice(0, 2).map((s, i) =>
                          <span key={i}>{s}{(i === 1 && list.length > 2) ? ' ...' : null}<br /></span>);
                        })()}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton size="large" color="inherit">
                          <Iconify icon={'eva:arrowhead-right-fill'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      {notFoundElement}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}
