
export function calcAge(birthDate, nowDate) {
  const birthNumber =
    birthDate.getFullYear() * 10000
    + (birthDate.getMonth() + 1 ) * 100
    + birthDate.getDate();

  const nowNumber =
    nowDate.getFullYear() * 10000
    + (nowDate.getMonth() + 1 ) * 100
    + nowDate.getDate();

  const age = Math.floor((nowNumber - birthNumber) / 10000);

  return Number.isNaN(age) ? null : age; 
}

export function calcTheAge(birthDate, nowDate) {
  return Math.floor(calcAge(birthDate, nowDate) / 10) * 10;
}

export function ascComparer(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) { return +1; }
  if (b[orderBy] > a[orderBy]) { return -1; }
  return 0;
}

export function descComparer(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) { return -1; }
  if (b[orderBy] > a[orderBy]) { return +1; }
  return 0;
}
