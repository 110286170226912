import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
// auth
import { useAuthContext } from '../../../auth/hooks';
// components
import Iconify from '../../../components/iconify';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
// hooks
import { useBoolean } from '../../../hooks/use-boolean';
// utils
import { fetchPostLog } from '../../../utils/apiAccess';

// ----------------------------------------------------------------------

export default function LoginForm({ returnTo }) {
  const { login, login2, logout, authenticated } = useAuthContext();
  const [errorMsg, setErrorMsg] = useState('');
  const [challenger, setChallenger] = useState(null);
  const password = useBoolean();
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    userid: Yup.string().required('ユーザーIDは必須です'),
    password: Yup.string().required('パスワードは必須です'),
    authcode: Yup.string().required('認証コードは必須です'),
  });

  const defaultValues = {
    userid: '',
    password: '',
    authcode: 'x',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      if(errorMsg) { setErrorMsg(''); }
      if(!challenger) {
        const user = await login?.(data.userid, data.password);
        if(user) {
          setValue('authcode', '');
          setChallenger(user);
          return;
        }
      } else {
        await login2?.(challenger, data.authcode);
      }
      fetchPostLog(null, { LogType: 'ログイン' });
      reset();
      navigate(returnTo ?? '/dashboard');
      
    } catch (error) {
      let errMsg = typeof error === 'string' ? error : error.message;
      if(/^(Incorrect|User)/.test(errMsg)) {
        errMsg = 'ユーザーIDまたはパスワードが正しくありません。';
      } else if(errMsg.endsWith('ERR_EMAIL.')) {
        errMsg = 'ログインにはメールアドレスの登録が必要です。';
      } else if(errMsg.endsWith('ERR_CODE.')) {
        errMsg = '認証コードが一致しません。';
      } else if(errMsg.endsWith('ERR_TIME.')) {
        errMsg = '認証コードの有効期限が切れました。もう一度、最初からやり直してください。';
        setChallenger(null);
        reset();
      }
      setErrorMsg(errMsg);
      fetchPostLog(null, {
        LogType: 'ログイン失敗',
        LogDat1: challenger ? 'factor-2' : 'factor-1',
        UserID: data.userid }, false);
    }
  });

  useEffect(() => {
    try {
      if(authenticated) {
        fetchPostLog(null, { LogType: 'ログアウト' });
        logout?.();
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3}>
        {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        <RHFTextField name="userid" label="ユーザーID" disabled={!!challenger} />
        <RHFTextField disabled={!!challenger}
          name="password"
          label="パスワード"
          type={password.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          autoComplete="new-password"
        />
        {challenger &&
          <>
          <Typography variant="inherit">登録メールに送信された「認証コード」を入力してください</Typography>
          <RHFTextField name="authcode" label="認証コード" />
          </>
        }
      </Stack>

      <LoadingButton fullWidth size="large" sx={{ mt: 6 }} type="submit"
        variant="contained" loading={isSubmitting}>
        ログイン
      </LoadingButton>

      <Stack justifyContent="start" sx={{ my: 3 }}>
        <Link variant="subtitle2" underline="always">
          IDをお忘れの方
        </Link>
        <Link variant="subtitle2" underline="always">
          パスワードをお忘れの方
        </Link>
      </Stack>
    </FormProvider>
  );
}

LoginForm.propTypes = {
  returnTo: PropTypes.string,
};
