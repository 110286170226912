import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

export function RHFDatePicker({ name, helperText, onChange, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          slotProps={{
            textField: {
              fullWidth: true,
              error: !!error,
              helperText: error ? error?.message : helperText,
            },
          }}
          onChange={(event) => {
            field.onChange(event);
            if(onChange) { onChange(event); };
          }}
          {...other}
        />
      )}
    />
  );
}

RHFDatePicker.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
};

// ----------------------------------------------------------------------

export function RHFDateTimePicker({ name, helperText, onChange, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DateTimePicker
          {...field}
          timeSteps={{ minutes: 1 }}
          closeOnSelect={false}
          slotProps={{
            textField: {
              fullWidth: true,
              error: !!error,
              helperText: error ? error?.message : helperText,
            },
            actionBar: {
              actions: ['clear', 'accept']
            }
          }}
          onChange={(event) => {
            field.onChange(event);
            if(onChange) { onChange(event); };
          }}
          {...other}
        />
      )}
    />
  );
}

RHFDateTimePicker.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
};
